<template>
  <div class="all-conected back-stage-main">
    <backStageHeader title="全连接平台接入配置">
      <div>
        <searchInput v-model="searchName" class="mg-r-40" placeholder="按平台名称查找" @search="searchData" />
        <el-button type="primary" size="small" icon="iconfont icon-tianjia" @click="handleAdd">新增关联</el-button>
      </div>
    </backStageHeader>

    <el-table :data="listData.list" class="back-stage-main_table" v-loading="loading">
      <el-table-column prop="areaName" label="地区" show-overflow-tooltip min-width="132"> </el-table-column>
      <el-table-column prop="areaPlatFormName" label="区域全连接平台名称" show-overflow-tooltip min-width="240">
      </el-table-column>
      <el-table-column prop="areaPlatFormUrl" label="区域全连接平台地址" show-overflow-tooltip min-width="280">
      </el-table-column>
      <el-table-column prop="qlAppKey" label="青鹿应用密钥" show-overflow-tooltip min-width="347"> </el-table-column>
      <el-table-column prop="status" label="操作" width="206" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handleEdit(scope.row, scope.$index)">编辑</el-button>
          <el-button type="text" size="small" @click="handleDelete(scope.row, scope.$index)" del>删除关联</el-button>
        </template>
      </el-table-column>
    </el-table>
    <qlPagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="listData.pageIndex"
      :page-size="listData.pageSize"
      :total="listData.total"
    ></qlPagination>

    <!-- dialog 新增/编辑 -->
    <el-dialog
      :title="`${form.id ? '编辑' : '新增'}关联`"
      :visible.sync="visible"
      custom-class="ql-dialog"
      width="642px"
      :close-on-click-modal="false"
      @closed="resetForm"
    >
      <el-form :model="form" :rules="rules" ref="form" label-width="146px" size="small">
        <el-form-item label="请选择地区" prop="countryCode">
          <el-select v-model="form.level" class="form-width mg-b-24" @change="changeAreaLevel">
            <el-option
              v-for="item in areaLevelList"
              :key="item.level"
              :value="item.level"
              :label="item.levelName"
            ></el-option>
          </el-select>
          <areaSelect
            ref="areaSelect"
            :placeholder="areaSelectName"
            :defaultValue="areaDefaultValue"
            :defaultLevel="form.level"
            class="form-width"
            @select="setAreaInfo"
          />
        </el-form-item>
        <el-form-item label="区域全连接平台名称" prop="areaPlatFormName">
          <el-input v-model="form.areaPlatFormName" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item label="区域全连接平台地址" prop="areaPlatFormUrl">
          <el-input v-model="form.areaPlatFormUrl" maxlength="100"></el-input>
          <p class="text-tip form-width mg-t-8">
            通过关联地区与区域全连接平台，实现所属地区的学校从区域全连接平台拉取数据
          </p>
        </el-form-item>
        <el-form-item label="APP KEY" prop="appKey">
          <el-input v-model="form.appKey" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item label="APP ID" prop="appId">
          <el-input v-model="form.appId" maxlength="100"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="visible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleSave" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/**
 * @desc 全连接平台接入配置
 */
// components
import { Button, Table, TableColumn, Dialog, Input, Select, Option, Form, FormItem } from 'element-ui';
import backStageHeader from '@/components/backStageHeader';
import searchInput from '@/components/searchInput';
import areaSelect from '@/components/areaSelect';
import qlPagination from '@/components/qlPagination';
// api
import { getAllConnectPage, deleteAllConnectConfig } from '@/api/back-stage';
// mixins
import relationMixin from './mixins/relationMixin';

export default {
  name: 'allConnectPlatform',
  components: {
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    backStageHeader,
    searchInput,
    areaSelect,
    qlPagination,
  },
  mixins: [relationMixin],
  data() {
    return {
      searchName: '',

      // 表格
      loading: false,
      listData: {
        list: [],
        total: 0,
        pageSize: 10,
        pageIndex: 1,
      },
    };
  },
  methods: {
    // 根据名称搜索
    searchData() {
      this.getDataList(true);
    },
    // 加载表格数据
    getDataList(reset = false) {
      this.loading = true;
      reset === true && (this.listData.pageIndex = 1);
      let { pageIndex, pageSize } = this.listData;
      getAllConnectPage({
        pageIndex,
        pageSize,
        platFormAreaName: this.searchName,
      })
        .then((res) => {
          if (res.result) {
            res.result.data.forEach((item) => {
              item.syncSchoolArray = item.syncSchoolName.split(',');
            });
            this.listData.list = res.result.data;
            this.listData.total = res.result.recordCount;
          } else {
            this.listData.list = [];
            this.listData.total = 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 切换条数
    handleSizeChange(val) {
      this.listData.pageSize = val;
      this.getDataList(true);
    },
    // 切换页码
    handleCurrentChange(val) {
      this.listData.pageIndex = val;
      this.getDataList();
    },
    // 删除
    handleDelete(row) {
      this.$msgbox
        .confirm('确定删除该关联吗？删除后，该地区将无法从区域全连接平台拉取数据', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          deleteAllConnectConfig({
            platFormConfigId: row.id,
          }).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
            // 判断是否为最后一页唯一一条数据
            let idx = this.listData.pageIndex;
            if (idx > 1 && this.listData.list && this.listData.list.length === 1) {
              idx--;
            }
            this.handleCurrentChange(idx);
          });
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.getDataList();
  },
};
</script>

<style lang="scss" scoped src="./../../index.scss"></style>
